import React from 'react';
import Layout from '../components/Layout';
import LicenseRecordForm from '../components/LicenseRecordForm';
import { useParams } from 'react-router-dom';

const LicenseRecordFormPage = () => {
  const { id } = useParams(); // Get record ID from route params (if editing)
  return (
    <Layout>
      <LicenseRecordForm recordId={id} />
    </Layout>
  );
};

export default LicenseRecordFormPage;
