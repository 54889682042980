import * as React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Event as EventIcon,
  LibraryBooks as LibraryBooksIcon,
  Apps as AppsIcon,
  Subscriptions as SubscriptionsIcon,
  People as PeopleIcon,
  Star as StarIcon,
  Security as SecurityIcon,
  Key as KeyIcon,
} from '@mui/icons-material';
import { getUserFromToken } from '../services/authService';

export const navListItems = (navigate) => {
  const user = getUserFromToken(); // Extract user details safely
  const userRoles = Array.isArray(user?.roles) ? user.roles : []; // Ensure roles is an array or fallback to empty

  // Helper function to validate role access
  const hasAccess = (allowedRoles) =>
    userRoles.length > 0 && allowedRoles.some((role) => userRoles.includes(role));

  return (
    <div>
      {/* Dashboard - Accessible to all users */}
      <ListItem button={true} onClick={() => navigate('/dashboard')}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>

      {/* Users Licenses - Accessible to customers */}
      {hasAccess(['customer']) && (
        <ListItem button={true} onClick={() => navigate('/my-licenses')}>
          <ListItemIcon>
            <KeyIcon />
          </ListItemIcon>
          <ListItemText primary="My Keys" />
        </ListItem>
      )}

      {/* Economic Calendar - SysAdmin only */}
      {hasAccess(['sys-admin']) && (
        <ListItem button={true} onClick={() => navigate('/calendar')}>
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <ListItemText primary="Economic Calendar" />
        </ListItem>
      )}

      {/* Users - Admin and SysAdmin only */}
      {hasAccess(['admin', 'sys-admin']) && (
        <ListItem button onClick={() => navigate('/users')}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
      )}

      {/* Applications - Admin and SysAdmin */}
      {hasAccess(['admin', 'sys-admin']) && (
        <ListItem button onClick={() => navigate('/applications')}>
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText primary="Applications" />
        </ListItem>
      )}

      {/* Subscriptions - Admin and SysAdmin */}
      {hasAccess(['admin', 'sys-admin']) && (
        <ListItem button onClick={() => navigate('/subscriptions')}>
          <ListItemIcon>
            <SubscriptionsIcon />
          </ListItemIcon>
          <ListItemText primary="Subscriptions" />
        </ListItem>
      )}

      {/* Licenses - SysAdmin */}
      {hasAccess(['sys-admin']) && (
        <ListItem button={true} onClick={() => navigate('/licenses')}>
          <ListItemIcon>
            <LibraryBooksIcon />
          </ListItemIcon>
          <ListItemText primary="Licenses" />
        </ListItem>
      )}

      {/* Features - SysAdmin only */}
      {hasAccess(['sys-admin']) && (
        <ListItem button={true} onClick={() => navigate('/features')}>
          <ListItemIcon>
            <StarIcon />
          </ListItemIcon>
          <ListItemText primary="Features" />
        </ListItem>
      )}

      {/* Risk Settings - SysAdmin */}
      {hasAccess(['sys-admin']) && (
        <ListItem button={true} onClick={() => navigate('/risksettings')}>
          <ListItemIcon>
            <SecurityIcon />
          </ListItemIcon>
          <ListItemText primary="Risk Settings" />
        </ListItem>
      )}
    </div>
  );
};