import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from '@mui/material';
import { getVixScore } from '../services/vixService';
import VixCard from '../components/VixCard';
import Layout from '../components/Layout';
import EconomicEventTimeline from '../components/EconomicEventTimeline';
import RiskStatusCard from '../components/RiskStatusCard';
import RiskAvailabilityCard from '../components/RiskAvailabilityCard';
import { RiskLevel } from '../helpers/RiskLevel';
import RoleBasedAccess from '../components/RoleBasedAccess';
import LicenseCount from '../components/LicenseCount';

const DashboardPage = () => {
  const [vixData, setVixData] = useState({
    vix_score: 0,
    prev_close: 0,
    open_price: 0,
    "52_week_high": 0,
    "52_week_low": 0,
    percentage_change: 0,
  });
  const [selectedDate] = useState(() => {
    const now = new Date();
    const localDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    return localDate.toISOString().split('T')[0];
  });

  const fetchVixData = useCallback(async () => {
    try {
      const data = await getVixScore();
      const percentage_change = ((data.vix_score - data.prev_close) / data.prev_close) * 100;
      setVixData({ ...data, percentage_change });
    } catch (error) {
      console.error('Error fetching VIX score:', error);
    }
  }, []);

  useEffect(() => {
    fetchVixData();

    const vixInterval = setInterval(fetchVixData, 10 * 60 * 1000); // Fetch every 10 mins

    return () => clearInterval(vixInterval);
  }, [fetchVixData]);

  return (
    <Layout>
      <Grid container spacing={3} mb={4}>
        {/* Economic Event Timeline */}
        <Grid item xs={12} md={6}>
          <EconomicEventTimeline date={selectedDate} />
        </Grid>

        {/* VixCard and other components */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <VixCard vixData={vixData} />
            </Grid>

            <RoleBasedAccess roles={['sys-admin']}>
            <Grid item xs={12}>
              <RiskAvailabilityCard showUpcomingOnly="false" />
            </Grid>
            </RoleBasedAccess>

            {/* Risk Status Cards - Display 2 per row */}
            <Grid item xs={12} md={6}>
              <RiskStatusCard riskLevel={RiskLevel.High} />
            </Grid>
            <Grid item xs={12} md={6}>
              <RiskStatusCard riskLevel={RiskLevel.Medium} />
            </Grid>
            <Grid item xs={12} md={6}>
              <RiskStatusCard riskLevel={RiskLevel.Low} />
            </Grid>
            <Grid item xs={12} md={6}>
              <RiskStatusCard riskLevel={RiskLevel.None} />
            </Grid>

            <RoleBasedAccess roles={['sys-admin']}>
            <Grid item xs={12}>
              <RiskAvailabilityCard />
            </Grid>
            </RoleBasedAccess>           

            <RoleBasedAccess roles={['sys-admin', 'admin']}>
              <LicenseCount allowedRoles={['sys-admin', 'admin']}/>  
            </RoleBasedAccess>
          </Grid>
        </Grid>
      </Grid>      
    </Layout>
  );
};

export default DashboardPage;