import axiosInstance from './authService';

const BASE_URL = '/api/Feature';

// Fetch all features
export const getAllFeatures = async (scope = "") => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/all?scope=${scope}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching features:', error);
    throw error;
  }
};

// Fetch a feature by ID
export const getFeatureById = async (id) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching feature with ID ${id}:`, error);
    throw error;
  }
};

// Create a new feature
export const createFeature = async (featureData) => {
  try {
    const response = await axiosInstance.post(`${BASE_URL}/create`, featureData);
    return response.data;
  } catch (error) {
    console.error('Error creating feature:', error);
    throw error;
  }
};

// Update an existing feature
export const updateFeature = async (id, featureData) => {
  try {
    const response = await axiosInstance.put(`${BASE_URL}/update/${id}`, featureData);
    return response.data;
  } catch (error) {
    console.error(`Error updating feature with ID ${id}:`, error);
    throw error;
  }
};

// Delete a feature
export const deleteFeature = async (id) => {
  try {
    await axiosInstance.delete(`${BASE_URL}/delete/${id}`);
  } catch (error) {
    console.error(`Error deleting feature with ID ${id}:`, error);
    throw error;
  }
};
