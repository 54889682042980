import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { changeAccountPassword } from '../services/accountService';
import { getUserFromToken } from '../services/authService';
import { useSnackbar } from 'notistack';

const ChangePasswordForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const user = getUserFromToken(); // Get user details from token

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const toggleShowPassword = () => setShowPassword((prev) => !prev);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { currentPassword, newPassword, confirmPassword } = formValues;

    if (newPassword !== confirmPassword) {
        enqueueSnackbar('New passwords do not match', { variant: "error" });
      return;
    }

    try {
      const payload = {
        userGuid: user.sid, // Retrieve userGuid from user details
        currentPassword,
        newPassword,
      };

      await changeAccountPassword(payload);
      enqueueSnackbar('Password changed successfully', { variant: 'success' });
      setFormValues({ currentPassword: '', newPassword: '', confirmPassword: '' });
    } catch (error) {
      console.error('Error changing password:', error);
      enqueueSnackbar('Failed to change password', { variant: 'error' });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400, mx: 'auto', mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Change Password
      </Typography>
      <TextField
        fullWidth
        label="Current Password"
        name="currentPassword"
        type={showPassword ? 'text' : 'password'}
        value={formValues.currentPassword}
        onChange={handleChange}
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={toggleShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        fullWidth
        label="New Password"
        name="newPassword"
        type={showPassword ? 'text' : 'password'}
        value={formValues.newPassword}
        onChange={handleChange}
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={toggleShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        fullWidth
        label="Confirm New Password"
        name="confirmPassword"
        type={showPassword ? 'text' : 'password'}
        value={formValues.confirmPassword}
        onChange={handleChange}
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={toggleShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        Change Password
      </Button>
    </Box>
  );
};

export default ChangePasswordForm;