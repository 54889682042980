import React from 'react';
import Layout from '../components/Layout';
import ApplicationList from '../components/ApplicationList';

const ApplicationListPage = () => {
  return (
    <Layout>
      <ApplicationList />
    </Layout>
  );
};

export default ApplicationListPage;
