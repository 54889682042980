import React, { useState, useEffect } from 'react';
import { Paper, Typography, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { getLicenses } from '../services/licenseService';
import { getUserFromToken } from '../services/authService';

const LicenseCount = ({ allowedRoles }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [licenseCount, setLicenseCount] = useState(0);
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    const checkAccessAndFetchLicenses = async () => {
      try {
        const user = getUserFromToken();

        // Validate the structure of the user object and roles
        if (!user || !Array.isArray(user.roles)) {
          console.error('Invalid user data or roles are not an array:', user);
          enqueueSnackbar('Error validating user roles', { variant: 'error' });
          return;
        }

        // Debug: Log user roles and allowed roles
        console.debug('User roles:', user.roles);
        console.debug('Allowed roles:', allowedRoles);

        if (user.roles.some((role) => allowedRoles.includes(role))) {
          setHasAccess(true);
          const licenses = await getLicenses();

          // Debug: Check fetched licenses
          console.debug('Fetched licenses:', licenses);

          setLicenseCount(licenses.length);
        } else {
          setHasAccess(false);
        }
      } catch (error) {
        console.error('Error fetching license count:', error);
        enqueueSnackbar('Error fetching license count', { variant: 'error' });
      }
    };

    checkAccessAndFetchLicenses();
  }, [allowedRoles, enqueueSnackbar]);

  if (!hasAccess) {
    return null; // Don't render anything if the user doesn't have access
  }

  return (
    <Grid item xs={12} md={6}>
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'purple',
          color: 'white',
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
          {licenseCount !== null ? licenseCount : 'Loading...'}
        </Typography>
        <Typography variant="body1">Total Licenses</Typography>
      </Paper>
    </Grid>
  );
};

export default LicenseCount;
