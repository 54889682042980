import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import SubscriptionForm from '../components/SubscriptionForm';
import { useNavigate, useParams } from 'react-router-dom';
import { getSubscriptionById, createSubscription, updateSubscription } from '../services/subscriptionService';
import { useSnackbar } from 'notistack';

const SubscriptionFormPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      const fetchSubscription = async () => {
        try {
          const data = await getSubscriptionById(id);
          setSubscriptionData(data);
        } catch (error) {
          enqueueSnackbar('Error fetching subscription data', { variant: 'error' });
        } finally {
          setLoading(false);
        }
      };
      fetchSubscription();
    } else {
      setLoading(false);
    }
  }, [id, enqueueSnackbar]);

  const handleSubmit = async (data) => {
    try {
      if (id) {
        await updateSubscription(id, data);
        enqueueSnackbar('Subscription updated successfully', { variant: 'success' });
      } else {
        await createSubscription(data);
        enqueueSnackbar('Subscription created successfully', { variant: 'success' });
      }
      navigate('/subscriptions');
    } catch (error) {
      enqueueSnackbar('Error saving subscription', { variant: 'error' });
    }
  };

  if (loading) {
    return (
      <Layout>
        <div>Loading...</div>
      </Layout>
    );
  }

  return (
    <Layout>
      <SubscriptionForm subscriptionData={subscriptionData} onSubmit={handleSubmit} />
    </Layout>
  );
};

export default SubscriptionFormPage;
