import React from 'react';
import Layout from '../components/Layout';
import ChangePasswordForm from '../components/ChangePasswordForm';

const ChangePasswordPage = () => {
  return (
    <Layout>
      <ChangePasswordForm />
    </Layout>
  );
};

export default ChangePasswordPage;