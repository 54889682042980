import React from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import config from '../config';

const AppTitleLogo = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/'); // Navigate back to the home page
  };

  return (
    <Typography
      variant="h6"
      color="inherit"
      noWrap
      sx={{ flexGrow: 1, cursor: 'pointer' }}
      onClick={handleClick}
    >
      {config.appTitle}
    </Typography>
  );
};

export default AppTitleLogo;
