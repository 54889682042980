import React from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
} from '@mui/material';

const MyLicenseEditModal = ({ open, onClose, onSubmit, brokerageAccountNumber, onChange }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        component="form"
        onSubmit={onSubmit}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Edit Brokerage Account
        </Typography>
        <TextField
          label="Brokerage Account Number"
          name="brokerageAccountNumber"
          value={brokerageAccountNumber}
          onChange={onChange}
          fullWidth
          margin="normal"
          required
        />
        <Box sx={{ mt: 2, textAlign: 'right' }}>
          <Button onClick={onClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MyLicenseEditModal;
