import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import FeatureForm from '../components/FeatureForm';
import { useNavigate, useParams } from 'react-router-dom';
import { getFeatureById, createFeature, updateFeature } from '../services/featureService';
import { useSnackbar } from 'notistack';

const FeatureFormPage = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // ID for editing
  const { enqueueSnackbar } = useSnackbar();
  const [featureData, setFeatureData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      const fetchFeature = async () => {
        try {
          const data = await getFeatureById(id);
          setFeatureData(data);
        } catch (error) {
          enqueueSnackbar('Error fetching feature data', { variant: 'error' });
        } finally {
          setLoading(false);
        }
      };
      fetchFeature();
    } else {
      setLoading(false);
    }
  }, [id, enqueueSnackbar]);

  const handleSubmit = async (data) => {
    try {
      if (id) {
        await updateFeature(id, data);
        enqueueSnackbar('Feature updated successfully', { variant: 'success' });
      } else {
        await createFeature(data);
        enqueueSnackbar('Feature created successfully', { variant: 'success' });
      }
      navigate('/features');
    } catch (error) {
      enqueueSnackbar('Error saving feature', { variant: 'error' });
    }
  };

  if (loading) {
    return (
      <Layout>
        <div>Loading...</div>
      </Layout>
    );
  }

  return (
    <Layout>
      <FeatureForm featureData={featureData} onSubmit={handleSubmit} />
    </Layout>
  );
};

export default FeatureFormPage;
