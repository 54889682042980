import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  Modal,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  ReplayCircleFilled as ReplayCircleFilledIcon,
  Key as KeyIcon,
  Sync as SyncIcon,
  ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import {
  getLicenseRecordsByUser,
  deleteLicenseRecord,
  renewLicenseRecord,
  createLicenseRecord,
  updateLicenseRecord,
  getLicenseKeyInfo,
  getLicenseRecord,
} from '../services/licenseRecordService';
import { getLicenses, generateNewKey } from '../services/licenseService';
import { getAllApplications } from '../services/applicationService';
import { getAllSubscriptions } from '../services/subscriptionService';
import LicenseRecordModal from './LicenseRecordModal';
import { getUserFromToken } from '../services/authService';

const UserLicenseRecords = ({ userId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [licenseRecords, setLicenseRecords] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [applications, setApplications] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [viewLicenseInfo, setViewLicenseInfo] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [modalType, setModalType] = useState(''); // 'new', 'existing', or 'edit'
  const [formValues, setFormValues] = useState({
    applicationId: '',
    subscriptionId: '',
    brokerageAccountNumber: '',
    existingLicenseBlobId: '',
    status: 1, // Default to Active
    numberOfMonthCycle: '', // For New License Record
  });

  const [openRenewModal, setOpenRenewModal] = useState(false);
  const [renewFormValues, setRenewFormValues] = useState({
    status: 1,
    numberOfMonthCycle: 1,
  });

  const user = getUserFromToken(); // Get current user's roles
  const isSysAdmin = user?.roles?.includes('sys-admin'); // Check if the user is a sys-admin
  const isAdmin = user?.roles?.includes('admin'); // Check if the user is a admin

  useEffect(() => {
    fetchLicenseRecords();
    fetchLicenses();
    fetchApplications();
    fetchSubscriptions();
  }, [userId]);

  const fetchLicenseRecords = async () => {
    try {
      const data = await getLicenseRecordsByUser(userId);
      setLicenseRecords(data);
    } catch (error) {
      enqueueSnackbar('Error fetching license records', { variant: 'error' });
    }
  };

  const fetchLicenses = async () => {
    try {
      const data = await getLicenses();
      setLicenses(data);
    } catch (error) {
      enqueueSnackbar('Error fetching licenses', { variant: 'error' });
    }
  };

  const fetchApplications = async () => {
    try {
      const data = await getAllApplications();
      setApplications(data);
    } catch (error) {
      enqueueSnackbar('Error fetching applications', { variant: 'error' });
    }
  };

  const fetchSubscriptions = async () => {
    try {
      const data = await getAllSubscriptions();
      setSubscriptions(data);
    } catch (error) {
      enqueueSnackbar('Error fetching subscriptions', { variant: 'error' });
    }
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this license record?');
    if (confirmed) {
      try {
        await deleteLicenseRecord(id);
        fetchLicenseRecords();
        enqueueSnackbar('License record deleted successfully', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error deleting license record', { variant: 'error' });
      }
    }
  };

  const handleKeyGeneration = async (licenseBlobId) => {
    try {
        await generateNewKey(licenseBlobId);
        fetchLicenseRecords();
        enqueueSnackbar('Key generated successfully', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error generating key', { variant: 'error' });        
      }
  };

  const handleSyncLicence = async (record = null) => {
    const recordDetails = await getLicenseRecord(record.id); // Fetch record details by ID
    const payload = {
      userId,
      applicationId: recordDetails.applicationId || '',
      subscriptionId: recordDetails.subscriptionId || '',
      licenseKey: recordDetails.licenseBlob?.key || '',
      brokerageAccountNumber: recordDetails.licenseBlob?.associatedAccount || '',
      status: recordDetails.active ? 1 : 0,
      expiresOn: recordDetails.licenseBlob?.expiresOn || '',
    };

    try{
      await updateLicenseRecord(record.id, payload); // Call updateLicenseRecord for editing
        enqueueSnackbar('License record synced successfully', { variant: 'success' });
    }catch (error) {
      enqueueSnackbar('Error syncing license record', { variant: 'error' });
    }
  };

  const handleOpenModal = async (type, record = null) => {
    setModalType(type);
    if (type === 'edit' && record) {
      try {
        const recordDetails = await getLicenseRecord(record.id); // Fetch record details by ID
        setFormValues({
          applicationId: recordDetails.applicationId || '',
          subscriptionId: recordDetails.subscriptionId || '',
          licenseKey: recordDetails.licenseBlob?.key || '',
          brokerageAccountNumber: recordDetails.licenseBlob?.associatedAccount || '',
          status: recordDetails.active ? 1 : 0,
          expiresOn: recordDetails.licenseBlob?.expiresOn || '',
        });
        setSelectedRecord(recordDetails);
      } catch (error) {
        enqueueSnackbar('Error fetching license record details', { variant: 'error' });
        return;
      }
    } else {
      setFormValues({
        applicationId: '',
        subscriptionId: '',
        licenseKey: '',
        brokerageAccountNumber: '',
        status: 1, // Default to Active
        numberOfMonthCycle: type === 'new' ? '' : undefined, // Only for New License Record
      });
      setSelectedRecord(null);
    }
    setOpenModal(true);
  };
  

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenViewModal(false);
    setSelectedRecord(null);
    setViewLicenseInfo(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const payload = {
      userId,
      applicationId: formValues.applicationId,
      subscriptionId: formValues.subscriptionId,
      brokerageAccountNumber: formValues.brokerageAccountNumber,
      licenseKey: formValues.licenseKey,
      status: formValues.status,
      numberOfMonthCycle: formValues.numberOfMonthCycle,
      expiresOn: formValues.expiresOn,
    };
  
    try {
      if (modalType === 'edit') {
        await updateLicenseRecord(selectedRecord.id, payload); // Call updateLicenseRecord for editing
        enqueueSnackbar('License record updated successfully', { variant: 'success' });
      } else {
        await createLicenseRecord(payload); // Call createLicenseRecord for new license record
        enqueueSnackbar('License record created successfully', { variant: 'success' });
      }
      fetchLicenseRecords(); // Refresh the table
      handleCloseModal(); // Close the modal
    } catch (error) {
      enqueueSnackbar('Error saving license record', { variant: 'error' });
    }
  };
  
  const handleViewLicenseInfo = async (id) => {
    try {
      const data = await getLicenseKeyInfo(id);
      setViewLicenseInfo(data);
      setOpenViewModal(true);
    } catch (error) {
      enqueueSnackbar('Error fetching license info', { variant: 'error' });
    }
  };

  const handleCopyLicenseKey = () => {
    if (viewLicenseInfo && viewLicenseInfo?.key) {
      navigator.clipboard.writeText(viewLicenseInfo?.key);
      enqueueSnackbar('License key copied to clipboard');
    }
  };

  const handleCopyAccountNumber = () => {
    if (viewLicenseInfo && viewLicenseInfo?.associatedAccount) {
      navigator.clipboard.writeText(viewLicenseInfo?.associatedAccount);
      enqueueSnackbar('Account number copied to clipboard');
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar('Copied to clipboard');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleRenew = (record) => {
    setRenewFormValues({
      status: record.active ? 1 : 0,
      numberOfMonthCycle: 1,
    });
    setSelectedRecord(record);
    setOpenRenewModal(true);
  };

  const handleCloseRenewModal = () => {
    setOpenRenewModal(false);
    setSelectedRecord(null);
  };

  const handleRenewSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      userId,
      status: renewFormValues.status,
      numberOfMonthCycle: renewFormValues.numberOfMonthCycle,
    };
    try {
      await renewLicenseRecord(selectedRecord.id, payload);
      fetchLicenseRecords();
      enqueueSnackbar('License record renewed successfully', { variant: 'success' });
      handleCloseRenewModal();
    } catch (error) {
      enqueueSnackbar('Error renewing license record', { variant: 'error' });
    }
  };

  const handleRenewFormChange = (e) => {
    const { name, value } = e.target;
    setRenewFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        License Records
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenModal('new')}
        >
          Create New
        </Button>
        {isSysAdmin && (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenModal('existing')}
        >
          Create New From Existing Key
        </Button>
        )}
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Application</TableCell>
              <TableCell>Subscription</TableCell>
              <TableCell>Account #</TableCell>
              <TableCell>Status</TableCell>
               <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {licenseRecords.map((record) => (
              <TableRow key={record.id}>
                <TableCell>{record.applicationName}</TableCell>
                <TableCell>{record.subscriptionName}</TableCell>
                <TableCell>{record.licenseBlob?.associatedAccount}
                  <Tooltip title="Copy Account #">
                  <IconButton onClick={() => handleCopy(record.licenseBlob?.associatedAccount)} sx={{ marginRight: '5px' }}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>                   
                </TableCell>
                <TableCell>{record.active ? 'Active' : 'Inactive'}</TableCell>
                <TableCell>{new Date(record.createdAt).toLocaleString()}</TableCell>
                <TableCell>
                  <Tooltip title="Copy Key">
                    <IconButton onClick={() => handleCopy(record.licenseBlob?.key)} sx={{ marginRight: '5px' }}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View License Info">
                    <IconButton onClick={() => handleViewLicenseInfo(record.id)}>
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit License Record">
                    <IconButton onClick={() => handleOpenModal('edit', record)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Sync License">
                    <IconButton onClick={() => handleSyncLicence(record)}>
                      <SyncIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Extend License">
                    <IconButton onClick={() => handleRenew(record)}>
                      <ReplayCircleFilledIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Generate New Key">
                    <IconButton onClick={() => handleKeyGeneration(record.licenseBlobId)}>
                      <KeyIcon />
                    </IconButton>
                  </Tooltip>
                  {/* Render Delete only for sys-admin */}
                  {(isSysAdmin || isAdmin) && (
                  <Tooltip title="Delete">
                    <IconButton color="secondary" onClick={() => handleDelete(record.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <LicenseRecordModal
        open={openModal}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
        formValues={formValues}
        onChange={handleChange}
        applications={applications}
        subscriptions={subscriptions}
        licenses={licenses}
        modalType={modalType}
      />

      {viewLicenseInfo && (
        <Modal open={openViewModal} onClose={handleCloseModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" gutterBottom>
              License Info
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography><strong>Key:</strong> {viewLicenseInfo?.key}</Typography>
              <IconButton onClick={handleCopyLicenseKey}>
                <ContentCopyIcon />
              </IconButton>
            </Box>
            <Typography><strong>Account:</strong>
              {viewLicenseInfo?.associatedAccount}
              <IconButton onClick={handleCopyAccountNumber}>
                <ContentCopyIcon />
              </IconButton>
             </Typography>
            <Typography><strong>Description:</strong> {viewLicenseInfo?.description}</Typography>
            <Typography><strong>Validate Equity Size:</strong> {viewLicenseInfo?.validateEquitySize ? 'Yes' : 'No'}</Typography>
            <Typography><strong>Equity Size Cap:</strong> {viewLicenseInfo?.validateEquitySize ? viewLicenseInfo?.equitySize : 'No Limit' }</Typography>
            <Typography><strong>License To:</strong> {viewLicenseInfo?.licenseToName}</Typography>
            <Typography><strong>Email:</strong> {viewLicenseInfo?.licenseToEmail}</Typography>
            <Typography><strong>Expires On:</strong> {new Date(viewLicenseInfo?.expiresOn).toLocaleDateString()}</Typography>
            <Typography><strong>Status:</strong> {viewLicenseInfo?.active ? 'Active' : 'Inactive'}</Typography>
            <Box sx={{ mt: 2, textAlign: 'right' }}>
              <Button onClick={handleCloseModal} variant="contained" color="primary">
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

       {/* Renew Modal */}
       <Modal open={openRenewModal} onClose={handleCloseRenewModal}>
        <Box
          component="form"
          onSubmit={handleRenewSubmit}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Renew License Record
          </Typography>
          <Select
            name="status"
            value={renewFormValues.status}
            onChange={handleRenewFormChange}
            fullWidth
            margin="normal"
            required
          >
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </Select>
          <TextField
            label="Number of Month Cycle"
            name="numberOfMonthCycle"
            type="number"
            value={renewFormValues.numberOfMonthCycle}
            onChange={handleRenewFormChange}
            fullWidth
            margin="normal"
            required
          />
          <Box sx={{ mt: 2, textAlign: 'right' }}>
            <Button onClick={handleCloseRenewModal} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Renew
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default UserLicenseRecords;