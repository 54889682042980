import React from 'react';
import Layout from '../components/Layout';
import SubscriptionList from '../components/SubscriptionList';

const SubscriptionListPage = () => {
  return (
    <Layout>
      <SubscriptionList />
    </Layout>
  );
};

export default SubscriptionListPage;
