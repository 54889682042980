import axiosInstance from './authService';

const BASE_URL = '/api/Account';

// Fetch user account details
export const getAccountDetails = async (customerGuid) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/Customer/${customerGuid}`); 
    return response.data; 
  } catch (error) {
    console.error('Error fetching account details:', error);
    throw new Error('Unable to fetch account details.');
  }
};

// Update user account details
export const updateAccountDetails = async (accountDetails) => {
  try {
    const response = await axiosInstance.put(`${BASE_URL}/UpdateCustomer`, accountDetails);
    return response.data; 
  } catch (error) {
    console.error('Error updating account details:', error);
    throw new Error('Unable to update account details.');
  }
};

// Change account password
export const changeAccountPassword = async (payload) => {
  try {
    const response = await axiosInstance.post(`${BASE_URL}/ChangePassword`, payload); 
    return response.data; 
  } catch (error) {
    console.error('Error changing account password:', error);
    throw new Error('Unable to change account password.');
  }
};
