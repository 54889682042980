import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isAuthenticated, getUserFromToken } from '../services/authService';

const PrivateRoute = ({ allowedRoles }) => {
  // Check if the user is authenticated
  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }

  // Retrieve user roles from the token
  const user = getUserFromToken();
  const userRoles = user?.roles || []; // Extract roles or fallback to an empty array

  // Check if the user has any of the allowed roles
  const hasAccess = allowedRoles.some((role) => userRoles.includes(role));

  return hasAccess ? <Outlet /> : <Navigate to="/unauthorized" replace />;
};

export default PrivateRoute;
