import axiosInstance from './authService';

const BASE_URL = '/api/Role';

// Fetch all roles
export const getAllRoles = async () => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/all`);
    return response.data;
  } catch (error) {
    console.error('Error fetching roles:', error);
    throw error;
  }
};
