import React from 'react';
import { Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import Logo from '../assets/Logo.png';

const AppTitleLogo = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/'); // Navigate back to the home page
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      <img src={Logo} alt={config.appTitle} style={{ height: '50px', marginRight: '10px' }} />
      <Typography
        variant="h8"
        color="inherit"
        noWrap
        sx={{ flexGrow: 1 }}
      >
        {config.appTitle}
      </Typography>
    </Box>
  );
};

export default AppTitleLogo;
