import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'background.paper',
        py: 4,
        borderTop: 1,
        borderColor: 'divider',
      }}
    >
      <Container maxWidth="lg">
        <Typography 
            variant='body2'
            align="center">
            Disclaimer
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ fontSize: '0.55rem', lineHeight: 1.5 }}
        >
          All rights, title, and interest in PROFITPULSE EA, including algorithms, software, and related technology, 
          remain with Fintech Strategies, LLC. PROFITPULSE EA is provided "AS IS" without any warranties, express 
          or implied. User acknowledges the inherent risks of using software for trading, including potential loss 
          of investment. Past performance does not guarantee future results. Fintech Strategies does not guarantee 
          profits or protect against losses. Fintech Strategies LLC does not provide financial advice. The information 
          and content provided are intended for educational and informational purposes only. Any investment decisions 
          you make should be based on your own research and assessment of your financial goals and risk tolerance. We 
          strongly recommend consulting with a qualified financial advisor or professional before making any investment 
          decisions. Past performance is not indicative of future results, and all investments carry some level of risk. 
          Fintech Strategies is not responsible for any financial losses or decisions made based on the information provided. 
          Fintech Strategies LLC is a software company. Fintech Strategies LLC does not provide commodity trading advice based 
          on, or tailored to, the commodity interest or cash market positions or other circumstances or characteristics of 
          particular clients, nor does it direct any subscriber accounts. We do not sell a business opportunity, “get rich quick” 
          program, or money-making system. We believe that with education and the right tools, individuals can be better prepared 
          to make investment decisions, but we do not guarantee success in our programs. We do not make earnings claims, efforts claims, 
          or claims that our software will make you any money. All material presented on our website, except third-party information 
          or graphics, is proprietary intellectual property and protected by copyright. Any duplication, reproduction, or distribution 
          is strictly prohibited. We are not financial advisors, asset managers, or investment advisors. Fintech Strategies LLC is a 
          software provider that offers tools to traders. The exceptional growth numbers achieved by some of our users depend on various 
          factors, and we do not guarantee any specific amount of growth or success of any software application.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
