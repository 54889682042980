import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import UserForm from '../components/UserForm';
import { useParams } from 'react-router-dom';
import { getUserById, createUser, updateUser } from '../services/userService';
import { useSnackbar } from 'notistack';

const UserFormPage = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      const fetchUser = async () => {
        try {
          const data = await getUserById(id);
          setUserData(data);
        } catch (error) {
          enqueueSnackbar('Error fetching user data', { variant: 'error' });
        } finally {
          setLoading(false);
        }
      };
      fetchUser();
    } else {
      setLoading(false);
    }
  }, [id, enqueueSnackbar]);

  const handleSubmit = async (data) => {
    try {
      if (id) {
        await updateUser(id, data);
        enqueueSnackbar('User updated successfully', { variant: 'success' });
      } else {
        await createUser(data);
        enqueueSnackbar('User created successfully', { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar('Error saving user', { variant: 'error' });
    }
  };

  if (loading) {
    return (
      <Layout>
        <div>Loading...</div>
      </Layout>
    );
  }

  return (
    <Layout>
      <UserForm userData={userData} onSubmit={handleSubmit} />
    </Layout>
  );
};

export default UserFormPage;
