import React from 'react';
import Layout from '../components/Layout';
import AccountForm from '../components/AccountForm';

const AccountPage = () => {
  return (
    <Layout>
      <AccountForm />
    </Layout>
  );
};

export default AccountPage;
