import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Select, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';

const FeatureForm = ({ featureData, onSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({
    name: '',
    value: '',
    scope: '',
  });

  useEffect(() => {
    if (featureData) {
      setFormValues(featureData);
    }
  }, [featureData]);

  const handleChange = (e) => {
    const { name, value, scope } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: name === 'value' && !isNaN(value) ? Number(value) : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onSubmit(formValues);
      enqueueSnackbar('Feature saved successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error saving feature', { variant: 'error' });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Typography variant="h5" gutterBottom>
        {featureData?.id ? 'Edit Feature' : 'Create Feature'}
      </Typography>
      <TextField
        label="Name"
        name="name"
        value={formValues.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Value"
        name="value"
        value={formValues.value}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
       <Select
          label="Select Scope"
          name="scope"
          value={formValues.scope}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        >
          <MenuItem value="" disabled>
            Select Scope
          </MenuItem>
          <MenuItem value="subscription">Subscription</MenuItem>
          <MenuItem value="application">Application</MenuItem>
          <MenuItem value="user">User</MenuItem>
          <MenuItem value="global">Global</MenuItem>
        </Select>
      <Box sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default FeatureForm;
