import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import MyLicenses from '../components/MyLicenses';
import { useSnackbar } from 'notistack';
import { getUserFromToken } from '../services/authService';
import { getAccountDetails } from '../services/accountService';
import BuyLicense from '../components/BuyLicense';

const MyLicensesPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [userId, setUserId] = useState(null);
  const [refreshFlag, setRefreshFlag] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const user = getUserFromToken();
        if (user?.sid) {
          const accountDetails = await getAccountDetails(user.sid);
          setUserId(accountDetails.id);
        }
      } catch (error) {
        enqueueSnackbar('Error fetching user details', { variant: 'error' });
      }
    };

    fetchUserDetails();
  }, []);

  const refreshLicenses = () => {
    setRefreshFlag((prev) => !prev); // Toggle refresh flag to trigger re-fetch in MyLicenses
  };

  if (!userId) {
    return (
      <Layout>
        <p>Loading...</p>
      </Layout>
    );
  }

  return (
    <Layout>
      <BuyLicense refreshLicenses={refreshLicenses} />
      <MyLicenses userId={userId} refreshFlag={refreshFlag} />
    </Layout>
  );
};

export default MyLicensesPage;
