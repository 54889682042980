import React, { Component } from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate();

  const handleHomeRedirect = () => {
    resetErrorBoundary();
    navigate('/');
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 10 }}>
      <Typography variant="h4" gutterBottom color="error">
        Oops! Something went wrong.
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
        We're sorry for the inconvenience. Our team is already working on it.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleHomeRedirect}
        sx={{ mt: 2 }}
      >
        Go Back to Home
      </Button>
      {process.env.NODE_ENV !== 'production' && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" color="error">
            Debug Info:
          </Typography>
          <Typography variant="body2">{error?.message}</Typography>
        </Box>
      )}
    </Container>
  );
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state to display the fallback UI
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught in ErrorBoundary:', error, errorInfo);
  }

  resetErrorBoundary = () => {
    this.setState({ hasError: false, error: null });
  };

  render() {
    if (this.state.hasError && process.env.NODE_ENV === 'production') {
      return (
        <ErrorFallback
          error={this.state.error}
          resetErrorBoundary={this.resetErrorBoundary}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
