import React from 'react';
import {
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
} from '@mui/material';

const LicenseRecordModal = ({
  open,
  onClose,
  onSubmit,
  formValues,
  onChange,
  applications,
  subscriptions,
  licenses,
  modalType,
}) => {
  const isEdit = modalType === 'edit';
  const isNew = modalType === 'new';
  const isExisting = modalType === 'existing';

  const handleLicenseChange = (e) => {
    const selectedLicense = licenses.find((license) => license.id === e.target.value);
    if (selectedLicense) {
      onChange({
        target: {
          name: 'brokerageAccountNumber',
          value: selectedLicense.associatedAccount || '',
        },
      });
    }
    onChange(e);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        component="form"
        onSubmit={onSubmit}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          {isNew
            ? 'Create New License Record'
            : isEdit
            ? 'Edit License Record'
            : 'Create New From Existing Key'}
        </Typography>
        <Select
          name="applicationId"
          value={formValues.applicationId}
          onChange={onChange}
          fullWidth
          displayEmpty
          margin="normal"
          required
        >
          <MenuItem value="" disabled>
            Select Application
          </MenuItem>
          {applications.map((app) => (
            <MenuItem key={app.id} value={app.id}>
              {app.name}
            </MenuItem>
          ))}
        </Select>
        <Select
          name="subscriptionId"
          value={formValues.subscriptionId}
          onChange={onChange}
          fullWidth
          displayEmpty
          margin="normal"
          required
        >
          <MenuItem value="" disabled>
            Select Subscription
          </MenuItem>
          {subscriptions.map((sub) => (
            <MenuItem key={sub.id} value={sub.id}>
              {sub.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          label="Brokerage Account Number"
          name="brokerageAccountNumber"
          value={formValues.brokerageAccountNumber}
          onChange={onChange}
          fullWidth
          margin="normal"
        />
        {isNew && (
          <TextField
            label="Number of Month Cycle"
            name="numberOfMonthCycle"
            type="number"
            value={formValues.numberOfMonthCycle}
            onChange={onChange}
            fullWidth
            margin="normal"
            required
          />
        )}
        {isExisting && (
          <Select
            name="existingLicenseBlobId"
            value={formValues.existingLicenseBlobId}
            onChange={handleLicenseChange} // Automatically updates brokerageAccountNumber
            fullWidth
            displayEmpty
            margin="normal"
            required
          >
            <MenuItem value="" disabled>
              Select Existing License
            </MenuItem>
            {licenses.map((license) => (
              <MenuItem key={license.id} value={license.id}>
                {`${license.licenseToName || 'N/A'} - ${license.description} - ${license.associatedAccount}`}
              </MenuItem>
            ))}
          </Select>
        )}
        {isEdit && (
          <TextField
            label="License Key"
            name="licenseKey"
            value={formValues.licenseKey}
            onChange={onChange}
            fullWidth
            margin="normal"
            required
          />
        )}
        <Select
          name="status"
          value={formValues.status}
          onChange={onChange}
          fullWidth
          margin="normal"
          required
        >
          <MenuItem value={1}>Active</MenuItem>
          <MenuItem value={0}>Inactive</MenuItem>
        </Select>
        {isEdit && (
        <TextField
          label="Expiration Date"
          name="expiresOn"
          type="date"
          value={formValues.expiresOn ? formValues.expiresOn.split('T')[0] : ''}
          onChange={onChange}
          fullWidth
          margin="normal"
            InputLabelProps={{
            shrink: true,
            }}
        />
        )}
        <Box sx={{ mt: 2, textAlign: 'right' }}>
          <Button onClick={onClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LicenseRecordModal;