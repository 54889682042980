import axiosInstance from './authService';

const API_BASE_URL = '/api/Transaction';

/**
 * Get all transactions
 * @returns {Promise<Array>} List of transactions
 */
export const getAllTransactions = async () => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching transactions');
  }
};

/**
 * Get transaction by ID
 * @param {string} transactionId - Transaction ID
 * @returns {Promise<Object>} Transaction details
 */
export const getTransactionById = async (transactionId) => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/${transactionId}`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching transaction details');
  }
};

/**
 * Create a new transaction
 * @param {Object} transactionData - Transaction payload
 * @returns {Promise<Object>} Newly created transaction
 */
export const createTransaction = async (transactionData) => {
  try {
    const response = await axiosInstance.post(`${API_BASE_URL}`, transactionData);
    return response.data;
  } catch (error) {
    throw new Error('Error creating transaction');
  }
};

/**
 * Update transaction status
 * @param {string} transactionId - Transaction ID
 * @param {string} status - New status (e.g., "completed", "failed")
 * @returns {Promise<Object>} Updated transaction
 */
export const updateTransactionStatus = async (transactionId, status) => {
  try {
    const response = await axiosInstance.patch(`${API_BASE_URL}/status/${transactionId}`,
      status.toString(), // Send the status as raw data
      {
        headers: {
          'Content-Type': 'text/plain', // Correct Content-Type for plain text
        },
      });
    return response.data;
  } catch (error) {
    throw new Error('Error updating transaction status'  + error.message);
  }
};

/**
 * Delete a transaction
 * @param {string} transactionId - Transaction ID
 * @returns {Promise<void>}
 */
export const deleteTransaction = async (transactionId) => {
  try {
    await axiosInstance.delete(`${API_BASE_URL}/${transactionId}`);
  } catch (error) {
    throw new Error('Error deleting transaction');
  }
};
