import React from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import AppTitleLogo from './AppTitleLogo';

const Header = ({ onLogoClick }) => {
  return (
    <>
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Box
            sx={{ flexGrow: 1, cursor: 'pointer' }}
            onClick={onLogoClick}
          >
            <AppTitleLogo />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
