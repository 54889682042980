import React from 'react';
import { getUserFromToken } from '../services/authService';

/**
 * Role-based access wrapper
 * @param {Object} props
 * @param {React.ReactNode} props.children - Component children to render if access is granted
 * @param {Array<string>} props.roles - List of roles that can access the wrapped component
 */
const RoleBasedAccess = ({ children, roles = [] }) => {
  const user = getUserFromToken(); // Get the current logged-in user
  const userRoles = user?.roles || ['customer']; // Fallback to "customer" role if roles are undefined

  if (!roles.some((role) => userRoles.includes(role))) {
    return null; // If no access, render nothing
  }

  return <>{children}</>; // Render children if access is granted
};

export default RoleBasedAccess;
