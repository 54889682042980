import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  Button,
  Modal,
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Replay as ReplayIcon,
  Sync as SyncIcon,
  ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';
import {
  getLicenseRecordsByUser,
  getLicenseRecord,
  updateLicenseRecord,
  getLicenseKeyInfo,
} from '../services/licenseRecordService';
import { generateNewKey } from '../services/licenseService';
import { useSnackbar } from 'notistack';
import MyLicenseEditModal from './MyLicenseEditModal';

const MyLicenses = ({ userId, refreshFlag }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [licenseRecords, setLicenseRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [brokerageAccount, setBrokerageAccount] = useState('000000');

  const handleOpenModal = async (record) => {
    try {
        const recordDetails = await getLicenseRecord(record.id); // Fetch record details by ID
        setBrokerageAccount(recordDetails.licenseBlob?.associatedAccount || '');
        setSelectedRecord(recordDetails);
    } catch (error) {
        enqueueSnackbar('Error fetching license record details', { variant: 'error' });
    }
    setModalOpen(true);
  };

  const handleBrokerageChange = (e) => {
    setBrokerageAccount(e.target.value);
  };

  const fetchLicenses = async () => {
    try {
      const records = await getLicenseRecordsByUser(userId);
      setLicenseRecords(records);
    } catch (error) {
      enqueueSnackbar('Error fetching licenses', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchLicenses();
  }, [userId, refreshFlag]);

  const handleCopyLicenseKey = (key) => {
    navigator.clipboard.writeText(key);
    enqueueSnackbar('License key copied to clipboard', { variant: 'success' });
  };

  const handleViewLicenseInfo = async (record) => {
    try {
      const licenseInfo = await getLicenseKeyInfo(record.id);
      setSelectedRecord(licenseInfo);
      setOpenViewModal(true);
    } catch (error) {
      enqueueSnackbar('Error fetching license info', { variant: 'error' });
    }
  };

  const handleUpdateLicense = async (e) => {
    e.preventDefault();

    const payload = {
        userId: selectedRecord.userId,
        applicationId: selectedRecord.applicationId,
        subscriptionId: selectedRecord.subscriptionId,
        brokerageAccountNumber: brokerageAccount,
        licenseKey: selectedRecord.licenseBlob?.key,
        status: selectedRecord.active ? 1: 0,
        expiresOn: selectedRecord.licenseBlob?.expiresOn,
    };

    try {
        await updateLicenseRecord(selectedRecord.id, payload); // Call updateLicenseRecord for editing
        enqueueSnackbar('License record updated successfully', { variant: 'success' });
        fetchLicenses(); // Refresh the table
        handleCloseModal();
    } catch (error) {
        enqueueSnackbar('Error saving license record', { variant: 'error' });
    }    
  };

  const handleRegenerateKey = async (licenseBlobId) => {
    try {
      await generateNewKey(licenseBlobId);
      enqueueSnackbar('License key regenerated successfully', { variant: 'success' });
    } catch {
      enqueueSnackbar('Error regenerating license key', { variant: 'error' });
    }
  };

  const handleSyncLicense = (record) => {
    enqueueSnackbar(`License synced successfully`, { variant: 'success' });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setOpenViewModal(false);
    setSelectedRecord(null);
  };

  return (
    <Paper sx={{ mt: 3, p: 2 }}>
      <Typography variant="h6" gutterBottom>
        My Keys
      </Typography>
      {licenseRecords.length === 0 ? (
        <Typography>No Keys Found</Typography>
      ) : (
        <Table>
          <TableHead>
            <TableRow>              
              <TableCell>Application</TableCell>
              <TableCell>Subscription</TableCell>
              <TableCell>Account #</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Expires On</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {licenseRecords.map((record) => (
              <TableRow key={record.id}>                
                <TableCell>{record.applicationName}</TableCell>
                <TableCell>{record.subscriptionName}</TableCell>
                <TableCell>{record.licenseBlob.associatedAccount}</TableCell>
                <TableCell>{record.active ? 'Active' : 'Inactive'}</TableCell>
                <TableCell>{new Date(record.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(record.licenseBlob.expiresOn).toLocaleString()}</TableCell>
                <TableCell>
                    <Tooltip title="Copy Key">
                      <IconButton onClick={() => handleCopyLicenseKey(record.licenseBlob.key)} sx={{ marginRight: '5px' }}>
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  <Tooltip title="View License Info">
                    <IconButton onClick={() => handleViewLicenseInfo(record)}>
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Update Account Number">
                    <IconButton onClick={() => handleOpenModal(record)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Regenerate Key">
                    <IconButton onClick={() => handleRegenerateKey(record.licenseBlobId)}>
                      <ReplayIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Sync License">
                    <IconButton onClick={() => handleSyncLicense(record)}>
                      <SyncIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {/* Modal for License Info */}
      {selectedRecord && (
              <Modal open={openViewModal} onClose={handleCloseModal}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    License Info
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography><strong>Key:</strong> {selectedRecord.key}</Typography>
                    <IconButton onClick={handleCopyLicenseKey}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Box>
                  <Typography><strong>Account:</strong> {selectedRecord.associatedAccount}</Typography>
                  <Typography><strong>Description:</strong> {selectedRecord.description}</Typography>
                  <Typography><strong>Validate Equity Size:</strong> {selectedRecord.validateEquitySize ? 'Yes' : 'No'}</Typography>
                  <Typography><strong>Equity Size Cap:</strong> {selectedRecord.validateEquitySize ? selectedRecord.equitySize : 'No Limit' }</Typography>
                  <Typography><strong>License To:</strong> {selectedRecord.licenseToName}</Typography>
                  <Typography><strong>Email:</strong> {selectedRecord.licenseToEmail}</Typography>
                  <Typography><strong>Expires On:</strong> {new Date(selectedRecord.expiresOn).toLocaleDateString()}</Typography>
                  <Typography><strong>Status:</strong> {selectedRecord.active ? 'Active' : 'Inactive'}</Typography>
                  <Box sx={{ mt: 2, textAlign: 'right' }}>
                    <Button onClick={handleCloseModal} variant="contained" color="primary">
                      Close
                    </Button>
                  </Box>
                </Box>
              </Modal>
            )}

        <MyLicenseEditModal
            open={isModalOpen}
            onClose={handleCloseModal}
            onSubmit={handleUpdateLicense}
            brokerageAccountNumber={brokerageAccount}
            onChange={handleBrokerageChange}
            />
    </Paper>
  );
};

export default MyLicenses;
