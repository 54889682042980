import axiosInstance from './authService';

const BASE_URL = '/api/LicenseRecord';

// Fetch all license records
export const getLicenseRecords = async () => {
  try {
    const response = await axiosInstance.get(BASE_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching license records:', error);
    throw error;
  }
};

// Fetch license records by user ID
export const getLicenseRecordsByUser = async (userId) => {
    try {
      const response = await axiosInstance.get(`${BASE_URL}/user?userId=${userId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching license records:', error);
      throw error;
    }
  };

// Fetch a specific license record by ID
export const getLicenseRecord = async (recordId) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/${recordId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching license record:', error);
    throw error;
  }
};

// Create a new license record
export const createLicenseRecord = async (recordData) => {
  try {
    const response = await axiosInstance.post(`${BASE_URL}/create`, recordData);
    return response.data;
  } catch (error) {
    console.error('Error creating license record:', error);
    throw error;
  }
};

// Update an existing license record by ID
export const updateLicenseRecord = async (recordId, recordData) => {
  try {
    const response = await axiosInstance.put(`${BASE_URL}/update/${recordId}`, recordData);
    return response.data;
  } catch (error) {
    console.error('Error updating license record:', error);
    throw error;
  }
};

export const updateLicenseRecordStatus = async (recordId, status) => {
  try {
    const response = await axiosInstance.patch(`${BASE_URL}/status/${recordId}`, status);
    return response.data;
  } catch (error) {
    throw new Error('Error updating transaction status');
  }
};

// Assign existing license to a license record
export const assignExistingLicense = async (data) => {
    try {
      const response = await axiosInstance.post(`${BASE_URL}/assign-existing`, data);
      return response.data;
    } catch (error) {
      console.error('Error assigning existing license:', error);
      throw error;
    }
  };

// Delete a license record by ID
export const deleteLicenseRecord = async (recordId) => {
  try {
    await axiosInstance.delete(`${BASE_URL}/delete/${recordId}`);
  } catch (error) {
    console.error('Error deleting license record:', error);
    throw error;
  }
};

/**
 * Renew a license record by ID.
 * @param {number} recordId - The ID of the license record to renew.
 * @returns {Promise<Object>} - The response data from the API.
 * @throws {Error} - Throws an error if the request fails.
 */
export const renewLicenseRecord = async (recordId, data) => {
    try {
      const response = await axiosInstance.post(`${BASE_URL}/renew/${recordId}`, data);
      return response.data;
    } catch (error) {
      console.error(`Error renewing license record with ID ${recordId}:`, error);
      throw error;
    }
};

/**
 * Fetch license key info for a specific license record by ID.
 * @param {string} licenseRecordId - The ID of the license record.
 * @returns {Promise<Object>} - License key info response.
 */
export const getLicenseKeyInfo = async (licenseRecordId) => {
    try {
      const response = await axiosInstance.get(`${BASE_URL}/licenseKeyInfo/${licenseRecordId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching license key info for record ID ${licenseRecordId}:`, error);
      throw error;
    }
  };

