import axiosInstance from './authService';

const BASE_URL = '/api/Application';

// Fetch all applications
export const getAllApplications = async () => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/all`);
    return response.data;
  } catch (error) {
    console.error('Error fetching applications:', error);
    throw error;
  }
};

// Fetch an application by ID
export const getApplicationById = async (id) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching application with ID ${id}:`, error);
    throw error;
  }
};

// Create a new application
export const createApplication = async (applicationData) => {
  try {
    const response = await axiosInstance.post(`${BASE_URL}/create`, applicationData);
    return response.data;
  } catch (error) {
    console.error('Error creating application:', error);
    throw error;
  }
};

// Update an existing application
export const updateApplication = async (id, applicationData) => {
  try {
    const response = await axiosInstance.put(`${BASE_URL}/update/${id}`, applicationData);
    return response.data;
  } catch (error) {
    console.error(`Error updating application with ID ${id}:`, error);
    throw error;
  }
};

// Delete an application
export const deleteApplication = async (id) => {
  try {
    await axiosInstance.delete(`${BASE_URL}/delete/${id}`);
  } catch (error) {
    console.error(`Error deleting application with ID ${id}:`, error);
    throw error;
  }
};
