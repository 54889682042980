import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Paper } from '@mui/material';
import Header from '../components/Header';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { getTheme } from '../theme';
import config from '../config';

const Terms = () => {
    const navigate = useNavigate();

    const handleLogoClick = () => {
      navigate('/'); // Navigate back to the home page
    };
  
    const theme = getTheme(config.themeColor); // Get theme from config
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header onLogoClick={handleLogoClick} />
    
      <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Terms of Use & Conditions of Sale
      </Typography>

      <Typography variant="body1" paragraph>
        IMPORTANT – PLEASE CAREFULLY READ AND UNDERSTAND OUR TERMS OF USE AND CONDITIONS OF SALE
        (“TERMS” OR “AGREEMENT”) BEFORE ACCESSING OR USING, OR PLACING AN ORDER THROUGH OUR SITE.
        THESE TERMS CONTAIN DISCLAIMERS OF WARRANTIES AND LIMITATIONS OF LIABILITIES (SEE SECTIONS 15
        AND 16). THESE TERMS FORM AN ESSENTIAL BASIS OF OUR AGREEMENT.
      </Typography>

      <Typography variant="body1" paragraph>
        Your use of https://app.fintechstrategies.ai/, including any sub-domains thereof, affiliated
        websites, and mobile applications (collectively, the “Site”), which are owned and maintained
        by FINTECH STRATEGIES, LLC (“Fintech Strategies,” “we,” “our,” “us”), are governed by the
        policies, terms, and conditions set forth below. By accessing, using, or placing an order
        over the Site, you agree to the terms set forth herein. If you do not agree to these terms
        and conditions in their entirety, you are not authorized to use the Site in any manner or
        form whatsoever.
      </Typography>

      <Typography variant="body1" paragraph>
      THIS AGREEMENT CONTAINS ARBITRATION AND CLASS ACTION WAIVER PROVISIONS THAT WAIVE YOUR RIGHT TO A COURT HEARING, RIGHT TO A JURY TRIAL, AND RIGHT TO PARTICIPATE IN A CLASS ACTION. ARBITRATION IS MANDATORY AND IS THE EXCLUSIVE REMEDY FOR ANY AND ALL DISPUTES UNLESS SPECIFIED BELOW IN SECTION 17 OR IF YOU OPT-OUT. PLEASE CAREFULLY REVIEW THE DISPUTE RESOLUTION PROVISIONS IN SECTION 17 BELOW WHICH DESCRIBES YOUR RIGHT TO OPT-OUT.
      </Typography>

      <Typography variant="body1" paragraph>
        You can review the most current version of the Terms at any time on this page (Terms and Conditions | FINTECH STRATEGIES). We reserve the right to update, change, or replace any part of these Terms by posting updates and/or changes to our Site. It is your responsibility to check this page periodically for changes.
      </Typography>

      <Typography variant="body1" paragraph>
      YOUR CONTINUED USE OF OR ACCESS TO THE SITE FOLLOWING THE POSTING OF ANY CHANGES CONSTITUTES BINDING ACCEPTANCE OF THOSE CHANGES.
      </Typography>
            
      {/* Section 1 */}
      <Typography variant="h5" gutterBottom>
        1. Site Use
      </Typography>
      <Typography variant="body1" paragraph>
        Fintech Strategies Site, products, and services are intended for adults only. By using the Site and agreeing to these Terms, you represent that you are at least the age of majority in your state or province of residence. If you use the Site, you affirm that you have the legal capacity to enter into a binding contract with us, and have read this Agreement and understand and agree to its terms.
      </Typography>

      {/* Section 2 */}
      <Typography variant="h5" gutterBottom>
        2. Changes to These Terms
      </Typography>
      <Typography variant="body1" paragraph>
        Fintech Strategies reserves the right to update, change, or replace any part of these Terms
        by posting updates and/or changes to our Site. You can review the most current version of
        these Terms at any time by visiting this page. It is your responsibility to check this page
        periodically for changes. Your continued use of, or access to, the Site following the
        posting of any changes constitutes your binding acceptance of those changes.
      </Typography>

      {/* Section 3 */}
      <Typography variant="h5" gutterBottom>
        3. Privacy and Security Disclosure
      </Typography>
      <Typography variant="body1" paragraph>
        Fintech Strategies Privacy Policy may be viewed at Privacy Policy | FINTECH STRATEGIES. The
        Privacy Policy is hereby incorporated into these Terms by reference and constitutes a part
        of this Agreement. Fintech Strategies reserves the right to modify the Privacy Policy at
        its sole discretion.
      </Typography>

      {/* Section 4 */}
      <Typography variant="h5" gutterBottom>
        4. No Investment Recommendations or Professional Advice
      </Typography>
      <Typography variant="body1" paragraph>
        Fintech Strategies does not provide personalized investment advice. Neither the Site nor any of Fintech Strategies products or services are intended to provide tax, legal, insurance, or investment advice. None of the content provided on the Site or through any of Eminence Pro’s products or services should be construed as an offer to sell, a solicitation of an offer to buy, or a recommendation for any security by Fintech Strategies or any third party. You alone are solely responsible for determining whether any investment, security or strategy, or any other product or service, is appropriate or suitable for you based on your investment objectives and personal and financial situation. You should consult a registered investment advisor, attorney, or tax professional regarding your particular financial situation, investing strategies, or specific legal or tax situation.
      </Typography>
      <Typography variant="body1" paragraph>
        To the extent that any of the content published on the Site may be deemed to be investment advice or recommendations in connection with a particular security, such information is impersonal and not tailored to the investment needs of any specific person. You understand that an investment in any security is subject to a number of risks, and that discussions of any security published on the Site will not contain a list or description of relevant risk factors.
      </Typography>
      <Typography variant="body1" paragraph>
        You understand that performance data is supplied by sources believed to be reliable, that the calculations on our Site are made using such data, and that such calculations are not guaranteed by these sources, the information providers, or any other person or entity, and may not be complete.
        From time to time, our Site may reference prior articles and opinions that we have published. These references may be selective, may reference only a portion of an article or opinion, and are likely not to be current. As markets change continuously, previously published information and data may not be current and should not be relied upon.
      </Typography>
      <Typography variant="body1" paragraph>
        All content on the Site is presented only as of the date published or indicated and may be superseded by subsequent market events or for other reasons. In addition, you are responsible for setting the cache settings on your browser to ensure you are receiving the most recent data.
      </Typography>

      {/* Section 5 */}
      <Typography variant="h5" gutterBottom>
        5. No Direct Lending
      </Typography>
      <Typography variant="body1" paragraph>
        Fintech Strategies is a software company. We do not offer any lending services, either
        personal or business. Your ability to obtain loans, including the amount, rate, and other
        terms, will ultimately depend on the determination of third-party lenders, which is beyond
        our control. We do not guarantee that you will be qualified for or receive any particular
        offer from any lender.
      </Typography>

      {/* Section 6 */}
      <Typography variant="h5" gutterBottom>
        6. Securities & Investing Disclosure
      </Typography>
      <Typography variant="body1" paragraph>
        Stocks and options trading have large potential rewards but also large potential risks. You must be aware of the risks and be willing to accept them to invest in the stocks and options markets. Don’t trade with money you can’t afford to lose. Fintech Strategies does not represent that any account will or is likely to achieve profits or losses similar to those discussed on the Site. The past performance of any trading system or methodology is not necessarily indicative of future results. All trades, patterns, charts, systems, etc., discussed on the Site are for illustrative purposes only and not to be construed as specific advisory recommendations. Information contained on the Site is intended for informational purposes only.
      </Typography>

      {/* Section 7 */}
      <Typography variant="h5" gutterBottom>
        7. Intellectual Property
      </Typography>
      <Typography variant="body1" paragraph>
        The Site and the content contained herein, as well as all copyrights, including without limitation, the text, documents, articles, products, software, graphics, photos, sounds, videos, interactive features, services, links, User Submissions (as defined below), third-party apps, and any other content on the Site (“Content”) and the trademarks, service marks, and logos contained therein are the property of Fintech Strategies and its third-party licensors or providers. You may access and use the Content and download and/or print out copies of any content from the Site solely for your personal, non-commercial use. If you download or print a copy of the Content for personal use, you must retain all copyright and other proprietary notices contained therein. You acknowledge that you do not acquire any ownership rights by using the Site. Fintech Strategies reserves all rights not expressly granted in and to the Site.
      </Typography>
      <Typography variant="body1" paragraph>
        You agree not to circumvent, disable, or otherwise interfere with security-related features of the Site or features that prevent or restrict use or copying of any Content or enforce limitations on use of the Site or the Content therein.
      </Typography>

      {/* Section 8 */}
      <Typography variant="h5" gutterBottom>
        8. Prohibited Use
      </Typography>
      <Typography variant="body1" paragraph>
        In addition to other prohibitions as set forth in these Terms, you are prohibited from using the Site or its Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial, or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.
      </Typography>

      {/* Section 9 */}
      <Typography variant="h5" gutterBottom>
        9. Termination
      </Typography>
      <Typography variant="body1" paragraph>
        The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.
      </Typography>
      <Typography variant="body1" paragraph>
        These Terms are effective unless and until terminated by either you or us. You may terminate these Terms at any time by notifying us that you no longer wish to use our Services, or when you cease using our Site.
        If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).
      </Typography>

      {/* Add more sections here */}
      <Typography variant="h5" gutterBottom>
        10. Prohibited Conduct
      </Typography>
      <Typography variant="body1" paragraph>
        You agree not to engage in any of the following prohibited activities:
      </Typography>
      <Typography variant="body1" paragraph>
        Copying, distributing, or disclosing any part of the Site in any medium, including without limitation by any automated or non-automated “scraping”; Using any automated system, including without limitation “robots,” “spiders,” “offline readers,” etc., to access the Site in a manner that sends more request messages to the servers than a human can reasonably produce in the same period of time by using a conventional online web browser (except that permission is granted to operators of public search engines); Transmitting spam, chain letters, or other unsolicited email; Attempting to interfere with, compromise the system integrity or security, or decipher any transmissions to or from the servers running the Site; Taking any action that imposes an unreasonable or disproportionately large load on our infrastructure; Uploading invalid data, viruses, worms, or other software agents through the Site; Collecting or harvesting any personally identifiable information, including account names; Using the Site for any commercial solicitation purposes; Impersonating another person or otherwise misrepresenting your affiliation with a person or entity.
      </Typography>

      <Typography variant="h5" gutterBottom>
        11. User Accounts
      </Typography>
      <Typography variant="body1" paragraph>
        You may be required to create an account and specify a password in order to use certain Services or features on the Site. To create an account, you must be at least 18 years old and you must provide truthful and accurate information about yourself. Don’t try to impersonate anyone else when you create your account. If your information changes at any time, please update your account to reflect those changes.
      </Typography>
      <Typography variant="body1" paragraph>
        In some cases, an account may be assigned to you by an administrator, such as your employer or educational institution. If you are using or logging into an account assigned to you by an administrator, additional terms may apply to your use of the Site. Moreover, your administrator may be able to access or disable your account without our involvement.
      </Typography>
      <Typography variant="body1" paragraph>
        You may not share your account with anyone else. Please keep your password confidential, and try not to use it on other websites. If you believe that your account has been compromised at any time, please notify your system administrator.
      </Typography>
      <Typography variant="body1" paragraph>
        To delete your account, please contact us at [info@fintechstrategies.com]. If you do, we’ll initiate the deletion of your account, but we may retain certain information from your account as required by law or for legitimate business purposes. When you delete your account, your profile is not visible to other users, but your public comments will remain on the Site. If you delete your account, you won’t be able to use some features that require a registered account.
      </Typography>

      <Typography variant="h5" gutterBottom>
        12. Account Responsibility
      </Typography>
      <Typography variant="body1" paragraph>
        You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to: (a) immediately notify Fintech Strategies of any unauthorized use of your account information or any other breach of security, and (b) ensure that you exit from your account at the end of each session. Fintech Strategies cannot and will not be liable for any loss or damage arising from your failure to comply with this requirement.
      </Typography>

      <Typography variant="h5" gutterBottom>
        13. Modifications to the Service and Prices
      </Typography>
      <Typography variant="body1" paragraph>
        Prices for our products are subject to change without notice. We reserve the right at any time to modify or discontinue the Service without notice at any time.
      </Typography>

      <Typography variant="h5" gutterBottom>
        14. Products or Services
      </Typography>
      <Typography variant="body1" paragraph>
        Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.
      </Typography>
      <Typography variant="body1" paragraph>
        We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region, or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at any time without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.
      </Typography>

      <Typography variant="h5" gutterBottom>
        15. Governing Law and Award
      </Typography>
      <Typography variant="body1" paragraph>
        The Agreement is governed by and constructed following the laws of the State of Wyoming, irrespective of conflict-of-law principles. Client agrees that the laws of the State of Wyoming shall govern any matter or dispute relating to or arising out of this Agreement, as well as any dispute of any kind that may arise between Client and the Company. The Parties agree that this choice of law, venue, and jurisdiction provision is not permissive, but rather mandatory in nature. Client hereby waives the right to any objection of venue, including assertion of the doctrine of forum non-conveniens or similar doctrine.
      </Typography>
      <Typography variant="body1" paragraph>
        The arbitrator shall adhere to the substantive law of the State of Wyoming, irrespective of its conflicts of laws principles. Any award issued shall include a confidential written opinion and shall be conclusive, subject to appeal under the Federal Arbitration Act (FAA). The judgment on the award by the arbitrator may be entered in any court of competent jurisdiction.
      </Typography>
      <Typography variant="body1" paragraph>
        <br/>
        Enforceability
        <br/>
        <br/>
        This provision persists beyond the termination of your account or relationship with Fintech Strategies, bankruptcy, assignment, or transfer. If the class action waiver is found unenforceable, rendering arbitration feasible as a class or representative action, the entire arbitration provision becomes null and void and inapplicable. In the event a portion of this arbitration provision (excluding the class action waiver) is deemed unenforceable, the remaining portions of this arbitration provision shall remain fully effective.
      </Typography>

      <Typography variant="h5" gutterBottom>
        16. Miscellaneous
      </Typography>
      <Typography variant="body1" paragraph>
        Failure or any delay in enforcing this arbitration provision concerning any specific Claim shall not constitute a waiver of any rights to demand arbitration later or in connection with any other Claims, except that all Claims must be brought within the one-year limitation period specified above. This provision constitutes the complete arbitration agreement between you and Fintech Strategies and shall not be altered except in writing by Fintech Strategies.
      </Typography>
      <Typography variant="body1" paragraph>
        <br/>
        Amendments
        <br/>
        <br/>
        Fintech Strategies reserves the right to amend this arbitration provision at any time. Your continued use of the Site, purchase of a product or service on or through the Site, or use or attempted use of an Fintech Strategies product or service affirms your consent to such changes. Should the changes to this arbitration provision be substantial, Fintech Strategies will provide notice and an opportunity to opt-out. Your continued use of the Site, purchase of a product or service on or through the Site, or use or attempted use of an Fintech Strategies product or service constitutes your consent to such substantial changes.
      </Typography>
      <Typography variant="body1" paragraph>
        YOU UNDERSTAND AND AGREE TO HAVE ANY CLAIMS DECIDED INDIVIDUALLY AND ONLY THROUGH BINDING, FINAL, AND CONFIDENTIAL ARBITRATION. YOU HAVE THE RIGHT TO OPT-OUT OF THIS ARBITRATION PROVISION WITHIN THIRTY (30) DAYS FROM THE DATE THAT YOU PURCHASE, USE, OR ATTEMPT TO USE A PRODUCT OR SERVICE PURCHASED ON OR THROUGH THE SITE (WHICHEVER COMES FIRST) BY WRITING TO US AT FINTECH STRATEGIES, ATTN: LEGAL DEPARTMENT, 1309 COFFEEN AVE STE 1200 SHERIDAN WY 82801. FOR YOUR OPT-OUT TO BE EFFECTIVE, YOU MUST SUBMIT A SIGNED WRITTEN NOTICE IDENTIFYING ANY PRODUCT OR SERVICE YOU PURCHASED, USED OR ATTEMPTED TO USE WITHIN THE 30 DAYS AND THE DATE YOU FIRST PURCHASED, USED OR ATTEMPTED TO USE THE PRODUCT OR SERVICE. IF MORE THAN THIRTY (30) DAYS HAVE PASSED, YOU ARE NOT ELIGIBLE TO OPT OUT OF THIS PROVISION AND YOU MUST PURSUE YOUR CLAIM THROUGH BINDING ARBITRATION AS SET FORTH IN THIS AGREEMENT. SHOULD EITHER PARTY FILE AN ACTION CONTRARY TO THIS PROVISION, THE OTHER PARTY MAY RECOVER ATTORNEY’S FEES AND COSTS UP TO $1,000.00.
      </Typography>

      <Typography variant="h5" gutterBottom>
        17. Indemnification
      </Typography>
      <Typography variant="body1" paragraph>
        To the fullest extent permitted by law, you agree to indemnify, defend, and hold harmless Fintech Strategies, its affiliates (including without limitation all affiliated professional entities), subsidiaries, and their directors, officers, employees, contractors, licensors, suppliers, representatives, proprietors, partners, shareholders, servants, principals, agents, predecessors, successors, assigns, accountants, and attorneys from and against any claims, actions, losses, liabilities, damages, expenses, demands, and costs of any kind, including, but not limited to, reasonable attorneys’ fees, arising out of, resulting from, or in any way connected with or related to (1) your breach of these Terms, the documents they incorporate by reference, or the Agreement; (2) your breach of any representations or warranties in this Agreement; or (3) your violation of any law or the rights of a third-party.
      </Typography>

      <Typography variant="h5" gutterBottom>
        18. Third-Party Websites and Links
      </Typography>
      <Typography variant="body1" paragraph>
        Our Site may include materials from third parties or links to third-party websites. Fintech Strategies is not liable for any third-party materials or websites. Please review carefully the third-party’s policies and practices and ensure understanding before engaging in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
      </Typography>

      <Typography variant="h5" gutterBottom>
        19. Testimonials, Reviews, and Other Submissions
      </Typography>
      <Typography variant="body1" paragraph>
        Fintech Strategies appreciates hearing from users and customers and welcomes comments regarding our services and products. By providing Fintech Strategies, you agree that any testimonial or product review is true, accurate, and does not infringe on the intellectual property rights of any third party. Fintech Strategies may use testimonials and/or product/service reviews, in whole or in part, along with the name and location of the person submitting it, for advertising relating to Fintech Strategies products or services. Testimonials represent the unique experience of the customers submitting them and do not necessarily reflect the experience you may have.
      </Typography>
      <Typography variant="body1" paragraph>
        Any submission or post to the Site and/or provided to us, including photographs, testimonials, ideas, know-how, techniques, questions, reviews, comments, and suggestions (collectively, “Submissions”), is treated as non-confidential and nonproprietary. We have the right to use, copy, distribute, display, publish, perform, sell, lease, transmit, adapt, and create derivative works from such Submissions by any means and in any form. You represent and warrant that you own or have sufficient rights to share the Submissions with us. All Submissions automatically become our sole and exclusive property and are not returned.
      </Typography>
      <Typography variant="body1" paragraph>
        Additionally, Fintech Strategies reserves the right to correct grammatical and typing errors, to shorten testimonials prior to publication or use, and to review all testimonials prior to publication or use. Fintech Strategies is under no obligation to use any, or any part of, any testimonial or product/service review submitted. By submitting a testimonial, you confirm that you have read, understood, and agree to these Terms. If you disagree with any part of these Terms, do not submit a testimonial.
      </Typography>

      <Typography variant="h5" gutterBottom>
        20. Electronic Communications
      </Typography>
      <Typography variant="body1" paragraph>
        You agree that we may communicate electronically with you, and such communications, along with notices, disclosures, agreements, and other communications provided to you electronically, are equivalent to communications in writing and have the same force and effect as if in writing and signed by the party sending the communication.
      </Typography>

      <Typography variant="h5" gutterBottom>
        21. Assignment
      </Typography>
      <Typography variant="body1" paragraph>
        You may not assign any of your rights under these Terms, and any such attempt will be null and void. Fintech Strategies and its affiliates may, at their discretion, transfer, without further consent or notification, all contractual rights and obligations pursuant to these Terms if some or all of Fintech Strategies business is transferred to another entity by way of merger, sale of its assets, or otherwise.
      </Typography>

      <Typography variant="h5" gutterBottom>
        22. No Waiver
      </Typography>
      <Typography variant="body1" paragraph>
        No waiver by Fintech Strategies of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition. The failure by Fintech Strategies to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.
      </Typography>

      <Typography variant="h5" gutterBottom>
        23. No Agency Relationship
      </Typography>
      <Typography variant="body1" paragraph>
        No joint venture, partnership, or agency relationship exists between you and Fintech Strategies as a result of these Terms.
      </Typography>

      <Typography variant="h5" gutterBottom>
        24. Prohibited Conduct
      </Typography>
      <Typography variant="body1" paragraph>
        You agree not to undertake any attempts to reverse engineer or disassemble any code or software from the algorithm or from the website or to otherwise access the algorithm or the website without authorization by circumvention of security protocols or other tools designed to interfere thereof.
      </Typography>

      <Typography variant="h5" gutterBottom>
        25. Liability Disclaimer
      </Typography>
      <Typography variant="body1" paragraph>
        Fintech Strategies does not accept responsibility for the security of your account, network, or operations of the algorithm. You agree that your use of the website is at your own risk
      </Typography>
      
    </Container>
    </ThemeProvider>
  );
};

export default Terms;
