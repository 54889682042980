import axiosInstance from './authService';

const BASE_URL = '/api/Subscription';

// Fetch all subscriptions
export const getAllSubscriptions = async () => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/all`);
    return response.data;
  } catch (error) {
    console.error('Error fetching subscriptions:', error);
    throw error;
  }
};

// Fetch a subscription by ID
export const getSubscriptionById = async (id) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching subscription with ID ${id}:`, error);
    throw error;
  }
};

// Create a new subscription
export const createSubscription = async (subscriptionData) => {
  try {
    const response = await axiosInstance.post(`${BASE_URL}/create`, subscriptionData);
    return response.data;
  } catch (error) {
    console.error('Error creating subscription:', error);
    throw error;
  }
};

// Update an existing subscription
export const updateSubscription = async (id, subscriptionData) => {
  try {
    const response = await axiosInstance.put(`${BASE_URL}/update/${id}`, subscriptionData);
    return response.data;
  } catch (error) {
    console.error(`Error updating subscription with ID ${id}:`, error);
    throw error;
  }
};

// Delete a subscription
export const deleteSubscription = async (id) => {
  try {
    await axiosInstance.delete(`${BASE_URL}/delete/${id}`);
  } catch (error) {
    console.error(`Error deleting subscription with ID ${id}:`, error);
    throw error;
  }
};
