import { createTheme } from '@mui/material/styles';

export const getTheme = (color) => {
  const colors = {
    white: {
      palette: {
        primary: { main: '#ffffff' },
        secondary: { main: '#f5f5f5' },
        background: { default: '#f5f5f5', paper: '#ffffff' },
        text: { primary: '#000000', secondary: '#4f4f4f' },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            outlined: {
              color: '#000000',
              borderColor: '#000000',
              '&:hover': {
                borderColor: '#666666',
              },
            },
            contained: {
              backgroundColor: '#ffffff',
              color: '#000000', // Black text for contained buttons
              '&:hover': {
                backgroundColor: '#e0e0e0',
              },
            },
            text: {
              color: '#000000', // Black text for text buttons
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              color: '#000000',
              '&:hover': {
                color: '#333333',
              },
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              color: '#000000',
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              color: '#000000',
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundColor: '#ffffff', // Paper remains white
              color: '#000000', // Text remains black
            },
          },
        },
      },
    },
    black: {
      palette: {
        primary: { main: '#000000' },
        secondary: { main: '#333333' },
        background: { default: '#121212', paper: '#1e1e1e' },
        text: { primary: '#ffffff', secondary: '#bbbbbb' },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            outlined: {
              color: '#ffffff',
              borderColor: '#ffffff',
              '&:hover': {
                borderColor: '#bbbbbb',
              },
            },
            contained: {
              color: '#ffffff',
            },
            text: {
              color: '#ffffff',
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              color: '#ffffff',
              '&:hover': {
                color: '#bbbbbb',
              },
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              color: '#ffffff',
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              color: '#ffffff',
            },
          },
        },
      },
    },
    grey: {
      palette: {
        primary: { main: '#808080' },
        secondary: { main: '#c0c0c0' },
        background: { default: '#e0e0e0', paper: '#f5f5f5' },
        text: { primary: '#333333', secondary: '#666666' },
      },
    },
    red: {
      palette: {
        primary: { main: '#d32f2f' },
        secondary: { main: '#f44336' },
        background: { default: '#fdecea', paper: '#ffffff' },
        text: { primary: '#000000', secondary: '#4f4f4f' },
      },
    },
    green: {
      palette: {
        primary: { main: '#388e3c' },
        secondary: { main: '#4caf50' },
        background: { default: '#e8f5e9', paper: '#ffffff' },
        text: { primary: '#000000', secondary: '#4f4f4f' },
      },
    },
    blue: {
      palette: {
        primary: { main: '#1976d2' },
        secondary: { main: '#2196f3' },
        background: { default: '#e3f2fd', paper: '#ffffff' },
        text: { primary: '#000000', secondary: '#4f4f4f' },
      },
    },
  };

  const commonComponents = {
    MuiButton: {
      styleOverrides: {
        outlined: {
          '&:hover': {
            borderColor: '#666666',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: '#666666',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 400,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40,
        },
      },
    },
  };

  return createTheme({
    ...colors[color] || colors.blue,
    components: {
      ...commonComponents,
      ...(colors[color]?.components || {}),
    },
  });
};
