import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { getAllApplications } from '../services/applicationService';
import { getAllSubscriptions } from '../services/subscriptionService';

const LicenseRecordForm = ({ initialData, onSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({
    userId: initialData?.userId || 0,
    applicationId: initialData?.applicationId || '',
    subscriptionId: initialData?.subscriptionId || '',
    brokerageAccountNumber: initialData?.brokerageAccountNumber || '',
    status: initialData?.status || 0,
    numberOfMonthCycle: initialData?.numberOfMonthCycle || 1,
  });

  const [applications, setApplications] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    fetchApplications();
    fetchSubscriptions();
  }, []);

  const fetchApplications = async () => {
    try {
      const data = await getAllApplications();
      setApplications(data);
    } catch (error) {
      enqueueSnackbar('Error fetching applications', { variant: 'error' });
    }
  };

  const fetchSubscriptions = async () => {
    try {
      const data = await getAllSubscriptions();
      setSubscriptions(data);
    } catch (error) {
      enqueueSnackbar('Error fetching subscriptions', { variant: 'error' });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onSubmit(formValues);
      enqueueSnackbar('License record saved successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error saving license record', { variant: 'error' });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        {initialData ? 'Edit License Record' : 'Create License Record'}
      </Typography>
      <TextField
        label="Application"
        name="applicationId"
        value={formValues.applicationId}
        onChange={handleChange}
        fullWidth
        select
        margin="normal"
      >
        {applications.map((app) => (
          <MenuItem key={app.id} value={app.id}>
            {app.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Subscription"
        name="subscriptionId"
        value={formValues.subscriptionId}
        onChange={handleChange}
        fullWidth
        select
        margin="normal"
      >
        {subscriptions.map((sub) => (
          <MenuItem key={sub.id} value={sub.id}>
            {sub.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Brokerage Account Number"
        name="brokerageAccountNumber"
        value={formValues.brokerageAccountNumber}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Status"
        name="status"
        value={formValues.status}
        onChange={handleChange}
        fullWidth
        select
        margin="normal"
      >
        <MenuItem value={0}>Inactive</MenuItem>
        <MenuItem value={1}>Active</MenuItem>
      </TextField>
      <TextField
        label="Billing Cycle (Months)"
        name="numberOfMonthCycle"
        type="number"
        value={formValues.numberOfMonthCycle}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Box sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default LicenseRecordForm;
