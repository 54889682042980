import React from 'react';
import Layout from '../components/Layout';
import FeatureList from '../components/FeatureList';

const FeatureListPage = () => {
  return (
    <Layout>
      <FeatureList />
    </Layout>
  );
};

export default FeatureListPage;
