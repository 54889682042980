import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Modal,
  Select,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import config from '../config'; 
import { useSnackbar } from 'notistack';
import {
  createLicenseRecord,
} from '../services/licenseRecordService';
import {
  getAllApplications,
} from '../services/applicationService';
import {
  getAllSubscriptions,
} from '../services/subscriptionService';
import {
  createTransaction,
} from '../services/transactionService';
import { login, logout, getUserFromToken } from '../services/authService';
import { getAccountDetails } from '../services/accountService';

const BuyLicense = ({ refreshLicenses }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [applications, setApplications] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [userId, setUserId] = useState(null);
  const [formValues, setFormValues] = useState({
    applicationId: '',
    subscriptionId: '',
  });
  const [transactionStatus, setTransactionStatus] = useState('');

  const steps = [
    'Choose Application',
    'Choose Subscription',
    'Payment',
    'License Created',
  ];

  const sysAdminCredentials = config.sysAdminCredentials;

  // Helper to temporarily authenticate as sys-admin
  const withSysAdminAccess = async (callback) => {
    const originalToken = localStorage.getItem('jwt_token');
    try {
      await login(sysAdminCredentials.username, sysAdminCredentials.password);
      await callback();
    } catch (error) {
      enqueueSnackbar('Error with sys-admin access', { variant: 'error' });
    } finally {
      if (originalToken) {
        localStorage.setItem('jwt_token', originalToken);
      } else {
        logout();
      }
    }
  };

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const user = getUserFromToken();
        if (user?.sid) {
          const accountDetails = await getAccountDetails(user.sid);
          setUserId(accountDetails.id);
        }
      } catch (error) {
        enqueueSnackbar('Error fetching user details', { variant: 'error' });
      }
    };

    fetchUserId();
    withSysAdminAccess(async () => {
      await fetchApplications();
      await fetchSubscriptions();
    });
  }, []);

  const fetchApplications = async () => {
    try {
      const data = await getAllApplications();
      setApplications(data);
    } catch (error) {
      enqueueSnackbar('Error fetching applications', { variant: 'error' });
    }
  };

  const fetchSubscriptions = async () => {
    try {
      const data = await getAllSubscriptions();
      setSubscriptions(data);
    } catch (error) {
      enqueueSnackbar('Error fetching subscriptions', { variant: 'error' });
    }
  };

  const handleBuyLicense = () => {
    setOpenModal(true);
    setActiveStep(0);
    setFormValues({ applicationId: '', subscriptionId: '' });
    setTransactionStatus('');
  };

  const handleNext = async () => {
    if (!userId) {
      enqueueSnackbar('User ID not found. Please try again.', { variant: 'error' });
      return;
    }

    if (activeStep === 0 && !formValues.applicationId) {
      enqueueSnackbar('Please select an application', { variant: 'warning' });
      return;
    }

    if (activeStep === 1 && !formValues.subscriptionId) {
      enqueueSnackbar('Please select a subscription', { variant: 'warning' });
      return;
    }

    if (activeStep === 2) {
      try {
        const subscription = subscriptions.find(
          (sub) => sub.id === formValues.subscriptionId
        );

        if (!subscription) {
          enqueueSnackbar('Invalid subscription. Please try again.', { variant: 'error' });
          return;
        }

        await withSysAdminAccess(async () => {
          const licenseRecord = await createLicenseRecord({
            userId,
            applicationId: formValues.applicationId,
            subscriptionId: formValues.subscriptionId,
            brokerageAccountNumber: '000000',
            status: 1,
            numberOfMonthCycle: 1,
          });

          await createTransaction({
            licenseId: licenseRecord.id,
            amount: subscription.price,
            status: 1,
          });

          enqueueSnackbar('License and transaction created successfully', {
            variant: 'success',
          });

          setTransactionStatus('completed');
          refreshLicenses(); // Refresh the licenses in MyLicenses component
        });
      } catch (error) {
        enqueueSnackbar('Error processing payment and creating license', { variant: 'error' });
        return;
      }
    }

    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setActiveStep(0);
    setTransactionStatus('');
  };

  return (
    <Box>
      <Button variant="contained" color="primary" onClick={handleBuyLicense}>
        Buy New License
      </Button>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: '400px',
          }}
        >
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box mt={3}>
            {activeStep === 0 && (
              <Select
                fullWidth
                value={formValues.applicationId}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, applicationId: e.target.value }))
                }
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Application
                </MenuItem>
                {applications.map((app) => (
                  <MenuItem key={app.id} value={app.id}>
                    {app.name}
                  </MenuItem>
                ))}
              </Select>
            )}
            {activeStep === 1 && (
              <Select
                fullWidth
                value={formValues.subscriptionId}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, subscriptionId: e.target.value }))
                }
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Subscription
                </MenuItem>
                {subscriptions.map((sub) => (
                  <MenuItem key={sub.id} value={sub.id}>
                    {sub.name} - ${sub.price}/month
                  </MenuItem>
                ))}
              </Select>
            )}
            {activeStep === 2 && (
              <Box>
                <Typography>
                  {transactionStatus === 'completed'
                    ? 'Payment completed successfully!'
                    : 'Click "Pay Now" to complete the payment.'}
                </Typography>
                {transactionStatus !== 'completed' && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                    onClick={handleNext}
                  >
                    Pay Now
                  </Button>
                )}
              </Box>
            )}
            {activeStep === 3 && (
              <Typography>
                Open your new license and set the brokerage account. You can copy the
                generated key or re-generate a new one at any time.
              </Typography>
            )}
            <Box mt={2} textAlign="right">
              {activeStep < steps.length - 1 && (
                <Button variant="contained" color="primary" onClick={handleNext}>
                  Next
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <Button variant="contained" color="primary" onClick={handleCloseModal}>
                  Close
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default BuyLicense;
