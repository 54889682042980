import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import ApplicationForm from '../components/ApplicationForm';
import { useNavigate, useParams } from 'react-router-dom';
import { getApplicationById, createApplication, updateApplication } from '../services/applicationService';
import { useSnackbar } from 'notistack';

const ApplicationFormPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [applicationData, setApplicationData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      const fetchApplication = async () => {
        try {
          const data = await getApplicationById(id);
          setApplicationData(data);
        } catch (error) {
          enqueueSnackbar('Error fetching application data', { variant: 'error' });
        } finally {
          setLoading(false);
        }
      };
      fetchApplication();
    } else {
      setLoading(false);
    }
  }, [id, enqueueSnackbar]);

  const handleSubmit = async (data) => {
    try {
      if (id) {
        await updateApplication(id, data);
        enqueueSnackbar('Application updated successfully', { variant: 'success' });
      } else {
        await createApplication(data);
        enqueueSnackbar('Application created successfully', { variant: 'success' });
      }
      navigate('/applications');
    } catch (error) {
      enqueueSnackbar('Error saving application', { variant: 'error' });
    }
  };

  if (loading) {
    return (
      <Layout>
        <div>Loading...</div>
      </Layout>
    );
  }

  return (
    <Layout>
      <ApplicationForm applicationData={applicationData} onSubmit={handleSubmit} />
    </Layout>
  );
};

export default ApplicationFormPage;
