import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { getAccountDetails, updateAccountDetails } from '../services/accountService';
import { getUserFromToken } from '../services/authService';
import { useSnackbar } from 'notistack';

const AccountForm = () => {
    const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({
    userGuid: '',
    name: '',
    email: '',
    active: '',
  });
  const [loading, setLoading] = useState(true);

  const user = getUserFromToken(); // Extract user details from the token

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        if (user?.sid) {
          const userData = await getAccountDetails(user.sid); // Fetch account details using userGuid
          setFormValues({
            userGuid: user.sid, // Keep userGuid fixed
            name: userData.name, // Name is editable
            email: userData.email, // Email is read-only
            active: userData.active,
          });
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value })); // Allow changes to name
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        userGuid: formValues.userGuid, // userGuid remains unchanged
        name: formValues.name, // Updated name
        email: formValues.email, // Email remains read-only
        active: formValues.active, 
      };

      await updateAccountDetails(payload); // Send updated account details
      enqueueSnackbar('Account details updated successfully', { variant: "success" });
    } catch (error) {
      console.error('Error updating account details:', error);
      enqueueSnackbar('Failed to update account details', { variant: "error"} );
    }
  };

  if (loading) return <Typography>Loading...</Typography>;

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400, mx: 'auto', mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Account Details
      </Typography>
      <TextField
        fullWidth
        label="Name"
        name="name"
        value={formValues.name}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Email"
        name="email"
        value={formValues.email}
        InputProps={{ readOnly: true }}
        margin="normal"
      />
      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        Save Changes
      </Button>
    </Box>
  );
};

export default AccountForm;