import React from 'react';
import Layout from '../components/Layout';
import LicenseRecordList from '../components/LicenseRecordList';

const LicenseRecordListPage = () => {
  return (
    <Layout>
        <LicenseRecordList />
    </Layout>
  );
};

export default LicenseRecordListPage;
