import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Chip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { getAllFeatures } from '../services/featureService';

const ApplicationForm = ({ applicationData, onSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    terminalId: '',
    active: true,
    features: '{}', // Initialize features as a serialized JSON string
  });

  const [availableFeatures, setAvailableFeatures] = useState([]);

  useEffect(() => {
    if (applicationData) {
      const parsedFeatures =
        typeof applicationData.features === 'string'
          ? JSON.parse(applicationData.features || '{}')
          : applicationData.features || {};
      setFormValues({
        ...applicationData,
        features: JSON.stringify(parsedFeatures), // Ensure features are stored as a JSON string
      });
    }
  }, [applicationData]);

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const features = await getAllFeatures('application');
        setAvailableFeatures(features);
      } catch (error) {
        enqueueSnackbar('Error fetching features', { variant: 'error' });
      }
    };

    fetchFeatures();
  }, [enqueueSnackbar]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFeatureChange = (event, value) => {
    const featureDict = value.reduce((acc, feature) => {
      acc[feature.name] = feature.value || '';
      return acc;
    }, {});

    setFormValues((prevValues) => ({
      ...prevValues,
      features: JSON.stringify(featureDict), // Serialize as JSON string
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        ...formValues,
        features: formValues.features, // Already serialized as JSON string
      };
      await onSubmit(payload);
      enqueueSnackbar('Application saved successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error saving application', { variant: 'error' });
    }
  };

  const selectedFeatures = Object.entries(JSON.parse(formValues.features || '{}')).map(
    ([key, value]) => ({ name: key, value })
  );

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Typography variant="h5" gutterBottom>
        {applicationData?.id ? 'Edit Application' : 'Create Application'}
      </Typography>
      <TextField
        label="Name"
        name="name"
        value={formValues.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Description"
        name="description"
        value={formValues.description}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Terminal ID"
        name="terminalId"
        value={formValues.terminalId}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <Autocomplete
        multiple
        options={availableFeatures}
        getOptionLabel={(option) => `${option.name || 'Unnamed'} (${option.value || 'No Value'})`}
        value={selectedFeatures}
        onChange={handleFeatureChange}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={`${option.name || 'Unnamed'} (${option.value || 'No Value'})`}
              {...getTagProps({ index })}
              key={option.name}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Features"
            placeholder="Select features"
            margin="normal"
          />
        )}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={formValues.active}
            onChange={handleChange}
            name="active"
          />
        }
        label="Active"
      />
      <Box sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default ApplicationForm;