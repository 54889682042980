import * as React from 'react';
import { CssBaseline, Drawer, AppBar, Toolbar, List, IconButton, Container, Box, Avatar, Popover, Button, Typography, ThemeProvider } from '@mui/material';
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon, Logout as LogoutIcon } from '@mui/icons-material';
import { navListItems } from './navListItems';
import { useNavigate } from 'react-router-dom';
import { logout, getUserFromToken } from '../services/authService';
import AppTitleLogo from './AppTitleLogo';
import config from '../config';
import { getTheme } from '../theme';

const Layout = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = getUserFromToken();
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleNavigateToAccount = () => {
    navigate('/account');
    handlePopoverClose();
  };

  const handleNavigateToChangePassword = () => {
    navigate('/change-password');
    handlePopoverClose();
  };

  const openPopover = Boolean(anchorEl);

  const theme = getTheme(config.themeColor); // Apply theme from config

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer} sx={{ marginRight: '36px' }}>
              <MenuIcon />
            </IconButton>
            <AppTitleLogo />
            <IconButton edge="end" color="inherit" onClick={handlePopoverOpen}>
              <Avatar src="/path-to-avatar.jpg" alt={user?.name || 'User'} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          open={open}
          onClose={toggleDrawer}
          sx={{
            width: 240,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: 240,
              boxSizing: 'border-box',
            },
          }}
        >
          <Toolbar>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <List>{navListItems(navigate)}</List>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            paddingTop: 1,
            backgroundColor: (theme) =>
              theme.palette.background.default,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {children}
          </Container>
        </Box>

        {/* Popover Menu */}
        <Popover
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box sx={{ p: 2, minWidth: 200 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar src="/path-to-avatar.jpg" alt={user?.name || 'User'} sx={{ mr: 2 }} />
              <Box>
                <Typography variant="subtitle1">{user?.name}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {user?.email}
                </Typography>
              </Box>
            </Box>
            <Button variant="outlined" color="primary" fullWidth sx={{ mb: 1 }} onClick={handleNavigateToAccount}>
              Account
            </Button>
            <Button variant="outlined" color="primary" fullWidth sx={{ mb: 1 }} onClick={handleNavigateToChangePassword}>
              Change Password
            </Button>
            <Button variant="outlined" color="secondary" startIcon={<LogoutIcon />} fullWidth onClick={handleLogout}>
              Sign Out
            </Button>
          </Box>
        </Popover>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;