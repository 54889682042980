import React from 'react';
import Layout from '../components/Layout';
import LicenseRecordList from '../components/LicenseRecordList';
import { useNavigate } from 'react-router-dom';

const LicenseRecordListPage = () => {
    const navigate = useNavigate();
  return (
    <Layout>
        <LicenseRecordList onEdit={(record) => navigate(`/license-records/edit/${record.id}`)} />
    </Layout>
  );
};

export default LicenseRecordListPage;
